<template>
	<div class="page">
		<el-descriptions title="首页金刚区" :colon="false">
			<el-descriptions-item label="开通VIP">
				<el-switch active-text="开启" inactive-text="关闭" v-model="config.index_vip"></el-switch>
			</el-descriptions-item>
			<el-descriptions-item label="活动专区">
				<el-switch active-text="开启" inactive-text="关闭" v-model="config.index_activity"></el-switch>
			</el-descriptions-item>
			<el-descriptions-item label="领券中心">
				<el-switch active-text="开启" inactive-text="关闭" v-model="config.index_coupon"></el-switch>
			</el-descriptions-item>
			<el-descriptions-item label="叮当商城">
				<el-switch active-text="开启" inactive-text="关闭" v-model="config.index_shop"></el-switch>
			</el-descriptions-item>
		</el-descriptions>
		<el-descriptions title="VIP" :colon="false">
			<el-descriptions-item label="VIP功能">
				<el-switch active-text="开启" inactive-text="关闭" v-model="config.vip"></el-switch>
			</el-descriptions-item>
		</el-descriptions>
		<el-descriptions title="能量值" :colon="false">
			<el-descriptions-item label="能量值功能">
				<el-switch active-text="开启" inactive-text="关闭" v-model="config.energy"></el-switch>
			</el-descriptions-item>
		</el-descriptions>
		<el-descriptions title="客服联系电话" :colon="false">
			<el-descriptions-item label="电话">
				<el-input size="small" v-model="config.phone" style="width: 300px;" />
			</el-descriptions-item>
		</el-descriptions>
		<el-descriptions title="个人中心" :colon="false">
			<el-descriptions-item label="我的电桩">
				<el-switch active-text="开启" inactive-text="关闭" v-model="config.my_private"></el-switch>
			</el-descriptions-item>
			<el-descriptions-item label="企业服务">
				<el-switch active-text="开启" inactive-text="关闭" v-model="config.my_business"></el-switch>
			</el-descriptions-item>
			<el-descriptions-item label="申请退款">
				<el-switch active-text="开启" inactive-text="关闭" v-model="config.my_reback"></el-switch>
			</el-descriptions-item>
			<el-descriptions-item label="充值记录">
				<el-switch active-text="开启" inactive-text="关闭" v-model="config.my_paylog"></el-switch>
			</el-descriptions-item>
			<el-descriptions-item label="能量钥匙">
				<el-switch active-text="开启" inactive-text="关闭" v-model="config.my_energy"></el-switch>
			</el-descriptions-item>
		</el-descriptions>
		<el-button size="small" style="width: 100px;margin-top: 20px;" @click="configEdit">保存</el-button>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import Vue from 'vue'
import store from '@/store'
import API from '@/service/api.js'
import { MessageBox, Notification , Message} from 'element-ui'

const config = ref({});
function configGet(){
	API.System.Config.detail({token: store.state.user.token, types:"function"}).then((res)=>{
		config.value = res || {};
	});
}
configGet();
function configEdit(){
	API.System.Config.edit({token: store.state.user.token, types:"function", cfg:JSON.stringify(config.value)}).then((res)=>{
		Notification({title: '成功',message: '操作成功',type: 'success'})
	});
}
</script>

<style lang="scss" scoped>
.el-tab-pane {
	min-height: 300px;
}
.box{
	padding: 30px;
	line-height: 30px;
	.mintitle {
		font-weight: bold;
		padding: 20px 0;
	}
}
.countBox{
	background-color: #fff;
	margin-top: 8px;
	display: flex;
	padding: 20px 80px;
	div{
		margin-right: 160px;
	}
	a{
		font-size: 24px;
		font-weight: bold;
	}
}
</style>
